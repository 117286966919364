<ng-container *transloco="let t; read: 'organization.settings.tax-dialog'">
  <h2 class="mat-dialog-title space-bottom text-l">{{ t('title') }}</h2>
  <div class="mat-typography mat-dialog-content">
    <app-tax-settings-form #form [taxSetting]="data"></app-tax-settings-form>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">{{ t('cancel') }}</button>
    <button
      mat-raised-button
      color="accent"
      [disabled]="form.form.invalid"
      (click)="onOkClick(form)"
    >
      {{ t('save') }}
    </button>
  </mat-dialog-actions>
</ng-container>

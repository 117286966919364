import { TranslocoModule } from '@jsverse/transloco';
import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { UnsavedChangesDialogComponent } from './unsaved-changes-dialog.component';
import { SystemMessageModule } from '@arrivage-components/system-message/system-message.module';

@NgModule({
  declarations: [UnsavedChangesDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    TranslocoModule,
    SystemMessageModule,
  ],
  exports: [UnsavedChangesDialogComponent],
})
export class UnsavedChangesDialogModule {}

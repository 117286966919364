<ul
  *transloco="let t; read: 'navigation.toolbar'"
  fxLayout
  fxLayoutGap="1rem"
  class="navigation-items"
  fxLayoutAlign="center center"
>
  <ng-container
    *ngIf="!settings?.preferences?.language || showLanguageSelectForDev"
  >
    <li>
      <app-language-selector></app-language-selector>
    </li>
  </ng-container>
  <ng-container *ngIf="user && activeOrganization && authUser">
    <li>
      <app-share-buttons></app-share-buttons>
    </li>
    <li>
      <app-notif-dropdown
        [notifications]="notifications"
        (notificationRead)="onNotificationRead($event)"
      ></app-notif-dropdown>
    </li>
    <li>
      <!-- Dropdown button -->
      <a
        fxLayoutAlign="start center"
        class="navigation-avatar-button"
        [matMenuTriggerFor]="profileMenu"
        [customTooltip]="user.firstName"
      >
        <button
          mat-button
          fxLayoutAlign="center center"
          class="toolbar-image-button"
        >
          <img
            class="image-avatar"
            [src]="user.pictureUrl ? user.pictureUrl : DEFAULT_PHOTO_URL"
          />
        </button>

        <!-- Dropdown menu -->
        <mat-menu #profileMenu xPosition="before">
          <button
            mat-menu-item
            class="button-with-icon"
            [routerLink]="['/user/edit']"
          >
            <mat-icon> account_circle </mat-icon>
            <span>{{ t('profile') }}</span>
          </button>
          <button mat-menu-item class="button-with-icon" (click)="onLogout()">
            <mat-icon> exit_to_app </mat-icon>
            <span>{{ t('logout') }}</span>
          </button>
        </mat-menu>
      </a>
    </li>
  </ng-container>
</ul>

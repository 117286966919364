{
  "name": "arrivage-app",
  "version": "1.71.1",
  "scripts": {
    "ng": "ng",
    "start": "NG_PERSISTENT_BUILD_CACHE=1; ng serve",
    "build": "ng build",
    "build-prod": "ng build --configuration production",
    "deploy-staging": "npm install && ng build --configuration=staging && firebase use arrivage-staging && firebase deploy --only hosting:staging",
    "deploy-production": "npm install && firebase use arrivage-pro && ng build --configuration=production && firebase deploy --only hosting:production",
    "test": "NG_PERSISTENT_BUILD_CACHE=1; ng test",
    "test-once": "npm run test --single-run",
    "lint": "ng lint",
    "lint-rule": "eslint --plugin \"@typescript-eslint\" --no-eslintrc --parser @typescript-eslint/parser --parser-options \"{project:./tsconfig.json, sourceType:module}\" \"./**/*.ts\" --rule",
    "e2e": "ng e2e",
    "i18n:extract": "transloco-keys-manager extract",
    "i18n:find": "transloco-keys-manager find -p assets/i18n/",
    "source-map-explorer": "source-map-explorer dist/arrivage-app/**/*.js",
    "update-model": "npm update --save @arrivage/model"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.13",
    "@angular/cdk": "^18.2.14",
    "@angular/common": "^18.2.13",
    "@angular/compiler": "^18.2.13",
    "@angular/core": "^18.2.13",
    "@angular/fire": "^18.0.1",
    "@angular/flex-layout": "15.0.0-beta.42",
    "@angular/forms": "^18.2.13",
    "@angular/google-maps": "^18.2.14",
    "@angular/material": "^18.2.14",
    "@angular/platform-browser": "^18.2.13",
    "@angular/platform-browser-dynamic": "^18.2.13",
    "@angular/router": "^18.2.13",
    "@angular/service-worker": "^18.2.13",
    "@arrivage/model": "^1.424.0",
    "@fortawesome/angular-fontawesome": "^0.10.1",
    "@fortawesome/fontawesome-svg-core": "^1.2.35",
    "@fortawesome/free-brands-svg-icons": "^5.15.3",
    "@fortawesome/free-solid-svg-icons": "^5.15.3",
    "@fullcalendar/angular": "^6.1.16",
    "@fullcalendar/core": "^6.1.15",
    "@fullcalendar/daygrid": "^6.1.11",
    "@fullcalendar/interaction": "^6.1.11",
    "@fullcalendar/rrule": "^6.1.11",
    "@jsverse/transloco": "^7.4.1",
    "@jsverse/transloco-locale": "^7.0.0",
    "@jsverse/transloco-messageformat": "^7.0.0",
    "@ngrx/component": "^18.1.1",
    "@ngrx/effects": "^18.1.1",
    "@ngrx/entity": "^18.1.1",
    "@ngrx/operators": "^18.0.0",
    "@ngrx/router-store": "^18.1.1",
    "@ngrx/schematics": "^18.1.1",
    "@ngrx/store": "^18.1.1",
    "@ngrx/store-devtools": "^18.1.1",
    "@sentry/angular": "^9.0.1",
    "@turf/distance": "^6.0.1",
    "angular2-uuid": "^1.1.1",
    "caniuse-lite": "^1.0.30001015",
    "date-fns": "^2.16.1",
    "firebase": "^10.11.1",
    "geojson": "^0.5.0",
    "html2canvas": "^1.4.1",
    "instantsearch.css": "^7.4.2",
    "libphonenumber-js": "^1.10.34",
    "messageformat": "^2.3.0",
    "ng2-pdf-viewer": "^10.2.2",
    "ngx-image-cropper": "^6.2.1",
    "ngx-mat-select-search": "^8.0.0",
    "ngx-papaparse": "^8.0.0",
    "ngx-sharebuttons": "^12.0.0",
    "pdfmake": "^0.1.72",
    "pdfmake-wrapper": "^2.1.2",
    "rrule": "^2.6.4",
    "rxfire": "^6.0.5",
    "rxjs": "^7.8.0",
    "ts-money": "^0.4.6",
    "tslib": "^2.1.0",
    "zone.js": "^0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.14",
    "@angular-eslint/builder": "18.4.3",
    "@angular-eslint/eslint-plugin": "18.4.3",
    "@angular-eslint/eslint-plugin-template": "18.4.3",
    "@angular-eslint/schematics": "18.4.3",
    "@angular-eslint/template-parser": "18.4.3",
    "@angular/cli": "^18.2.14",
    "@angular/compiler-cli": "^18.2.13",
    "@angular/language-service": "^18.2.13",
    "@ngneat/spectator": "^18.0.2",
    "@ngneat/transloco-keys-manager": "^3.4.1",
    "@trivago/prettier-plugin-sort-imports": "^4.3.0",
    "@types/jasmine": "~3.6.0",
    "@types/lodash": "^4.14.165",
    "@types/node": "^20.17.18",
    "@types/pdfmake": "^0.1.21",
    "@typescript-eslint/eslint-plugin": "^8.25.0",
    "eslint": "^8.57.0",
    "jasmine-core": "^5.1.1",
    "jasmine-spec-reporter": "^7.0.0",
    "karma": "^6.3.16",
    "karma-chrome-launcher": "^3.1.0",
    "karma-coverage-istanbul-reporter": "^3.0.3",
    "karma-jasmine": "^4.0.1",
    "karma-jasmine-html-reporter": "^1.7.0",
    "karma-mocha-reporter": "^2.2.5",
    "ng-mocks": "^14.13.2",
    "prettier": "^3.2.5",
    "pretty-quick": "^1.11.1",
    "ts-node": "~7.0.0",
    "tslint": "~6.1.0",
    "typescript": "^5.4.5"
  },
  "engines": {
    "node": "=20.11.1",
    "npm": "=10.2.4"
  }
}

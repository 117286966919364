import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { LanguageService } from '@arrivage-language/service/language.service';
import { IdService } from '@arrivage-services/id.service';
import { TaxSetting, Translatable } from '@arrivage/model/dist/src/model';

@Component({
  selector: 'app-tax-settings-form',
  templateUrl: './tax-settings-form.component.html',
  styleUrls: ['./tax-settings-form.component.scss'],
})
export class TaxSettingsFormComponent implements OnInit {
  @Input()
  taxSetting: TaxSetting;

  form = this.fb.group({
    taxName: [null, Validators.required],
    taxRate: [
      null,
      Validators.compose([Validators.required, Validators.min(0)]),
    ],
    taxNumber: [null],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private idService: IdService,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    if (this.taxSetting) {
      this.form.controls['taxName'].setValue(
        this.languageService.getText(this.taxSetting.tax.name)
      );
      this.form.controls['taxRate'].setValue(this.taxSetting.tax.rate);
      if (this.taxSetting.taxNumber) {
        this.form.controls['taxNumber'].setValue(this.taxSetting.taxNumber);
      }
    }
  }

  get taxNameFormControl() {
    return this.form.controls['taxName'];
  }

  getRecord(): [boolean, TaxSetting] {
    if (this.form.invalid) {
      return [false, undefined];
    }
    const taxId = this.taxSetting
      ? this.taxSetting.taxId
      : this.idService.createId();

    return [
      true,
      {
        taxId: taxId,
        tax: {
          name: this.getTaxName(),
          rate: this.form.controls['taxRate'].value,
        },
        taxNumber: this.form.controls['taxNumber'].value,
      },
    ];
  }

  private getTaxName(): Translatable {
    if (!this.taxSetting || this.form.controls['taxName'].dirty) {
      // no settings to start with or name was changed
      return this.languageService.fromText(this.form.controls['taxName'].value);
    } else {
      return this.taxSetting.tax.name;
    }
  }
}

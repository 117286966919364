import * as Sentry from '@sentry/angular';

import { ErrorHandler } from '@angular/core';

class ExtendedErrorHandler extends ErrorHandler {
  private readonly chunkFailedMessage = /Loading chunk [\d]+ failed/;

  constructor(private delegate: ErrorHandler) {
    super();
  }

  override handleError(error: any) {
    if (this.chunkFailedMessage.test(error.message)) {
      window.location.reload();
    } else {
      console.log(`ExtendedErrorHandler: error message ${error.message}`);
      this.delegate.handleError(error);
    }
  }
}

export function createErrorHandler(): ErrorHandler {
  return new ExtendedErrorHandler(
    Sentry.createErrorHandler({
      showDialog: false,
    })
  );
}

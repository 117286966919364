import { Observable } from 'rxjs';

import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';

import { Store } from '@ngrx/store';

import { AuthFacade } from '@arrivage-auth/api/auth.facade';
import { AuthUser } from '@arrivage-auth/model/auth-data.model';
import { FormDirtyPageComponent } from '@arrivage-forms/form-is-dirty/form-dirty.model';
import { DirtyCheckService } from '@arrivage-services/dirty-check.service';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { ContextFacade } from '@arrivage-store/api/context.facade';
import { ContextFeedback } from '@arrivage-store/context/context.effect';
import { getOrganization } from '@arrivage-store/context/context.selectors';
import { State } from '@arrivage-store/state';
import {
  Organization,
  OrganizationSettings,
  WithId,
} from '@arrivage/model/dist/src/model';

import { OrganizationSettingsViewComponent } from '@arrivage-organization-settings/components/organization-settings-view/organization-settings-view.component';
import { OrganizationSettingsFacade } from '../api/organization-settings.facade';

@Component({
  selector: 'app-organization-settings-page',
  templateUrl: './organization-settings-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationSettingsPageComponent
  extends FormDirtyPageComponent
  implements OnInit
{
  organization$: Observable<Organization & WithId>;
  organizationSettings$: Observable<OrganizationSettings & WithId>;
  authUser$: Observable<AuthUser> = this.authFacade.getAuthUser();

  @ViewChild(OrganizationSettingsViewComponent)
  organizationSettingsView: OrganizationSettingsViewComponent;

  constructor(
    private store: Store<State>,
    private settingsFacade: OrganizationSettingsFacade,
    private contextFacade: ContextFacade,
    private snackbarService: SnackbarService,
    dirtyCheckService: DirtyCheckService,
    private readonly authFacade: AuthFacade
  ) {
    super(dirtyCheckService, true);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.settingsFacade.loadOrganizationSettings();
    this.organization$ = this.store.select(getOrganization);
    this.organizationSettings$ = this.settingsFacade.getOrganizationSettings();
  }

  override async saveAndQuit(): Promise<boolean> {
    const organizationRecord = await this.organizationSettingsView.getRecord();
    return await this.onUpdateOrganization(organizationRecord);
  }

  onUpdateOrganization(value: {
    organization: Partial<Organization> & WithId;
    organizationSettings: Partial<OrganizationSettings> & WithId;
  }): Promise<boolean> {
    const action = ContextFeedback.update_organization;
    return Promise.all([
      this.settingsFacade.updateOrganizationSettings(
        value.organizationSettings
      ),
      this.contextFacade.onUpdateOrganization(value),
    ])
      .then((x) => {
        this.organizationSettingsView.markFormAsPristine();
        this.snackbarService.showSuccess(action);
        return true;
      })
      .catch((e) => {
        this.snackbarService.showError(action);
        return false;
      });
  }
}
